import React, { useState } from 'react'
import { FieldProps } from 'formik'
import { GridMenuCell, GridMenuActionLink } from '../../../../shared/grid/GridMenu'
import { DocumentItemModel, DocumentModel, CompanyPrintSettingsModel, DocumentDetails } from '../../../models'
import { InlineInputEdit } from '../../../../shared/forms/InlineInputEdit'
import { InlineCurrencyEdit } from '../../../../shared/forms/InlineCurrencyEdit'
import { CurrencyLabel } from '../../../../shared/ui/currency'
import { DocumentItemForm } from './DocumentItemForm'
import { UnitItemModel } from '../../../../services/UnitsApi'
import round from '../../../../shared/utils/round'
import * as Yup from 'yup'
import { InlineNumberEdit } from '../../../forms/InlineNumberEdit'
import { ImageWithAuthorization } from '../../../elements/ImageWithAuthorization'
import changeItemField from '../../../../shared/utils/changeItemField'
import { useOfferionTranslation } from '../../../store/hooks/useOfferionTranslation'

export const DocumentItemRow = (
    props: FieldProps<DocumentItemModel, DocumentModel<DocumentDetails>> & {
        removeRow: () => void
        printSettings: CompanyPrintSettingsModel
        enterFullPriceEnabled: boolean
        isAccountingVat: boolean
        canChangeVat: boolean
        defaultVat: number
        editPurchasePrice: boolean
        units: UnitItemModel[]
        canSubmit: boolean
        showProductDimensionsInfo: boolean
    }
) => {
    const { t } = useOfferionTranslation()
    const exchangeRate = props.form.values.details.clientCurrencyRate / props.form.values.details.documentCurrencyRate

    const [editOverlayVisible, setEditOverlayVisible] = useState(false)
    const { enterFullPriceEnabled } = props

    const onRowFieldChange = (propertyName: string, newValue: number) => {
        const object: any = props.field.value

        changeItemField(object, propertyName, newValue, enterFullPriceEnabled)
    }

    const discountSchema = Yup.object().shape({
        newValue: Yup.number().required().min(0).max(99.99),
    })

    const quantitySchema = Yup.object().shape({
        newValue: Yup.number().required().notOneOf([0]),
    })

    return (
        <li className="table__row">
            <div className="table__cell__item-title mobile-only">
                <InlineInputEdit
                    currentValue={props.field.value.name}
                    preventSubmitOnEnter={true}
                    multiline
                    as="textarea"
                    placeholderKey="Invoice.Table.Item.titlePlaceholder"
                    onSave={({ newValue }) => {
                        props.form.setFieldValue(`${props.field.name}.name`, newValue)
                        props.form.submitForm()
                    }}
                />
            </div>
            <div className="table__cell__subtitle mobile-only">
                <InlineInputEdit
                    currentValue={props.field.value.additionalNote}
                    multiline
                    as="textarea"
                    preventSubmitOnEnter={true}
                    placeholderKey="Invoice.Table.Item.descriptionPlaceholder"
                    className={
                        props.field.value.additionalNote
                            ? 'subscript-note ng-inline-edit__text'
                            : 'subscript-note note-placeholder ng-inline-edit__text'
                    }
                    onSave={({ newValue }) => {
                        props.form.setFieldValue(`${props.field.name}.additionalNote`, newValue)
                        props.form.submitForm()
                    }}
                />
            </div>

            <InlineInputEdit
                currentValue={props.field.value.index.toString()}
                component="number"
                required={true}
                className="table__cell table__item-inline-title table__cell--prefixed table__item-inline-title--large-only overlay__item overlay__item--right-middle overlay__item--custom text-center"
                onSave={({ newValue }) => {
                    const index = Number.parseInt(newValue)

                    props.field.value.index = index

                    const array = [...props.form.values.items]

                    const newArray = array.sort((a, b) => a.index - b.index)

                    props.form.setFieldValue('items', newArray)

                    props.form.submitForm()
                }}>
                {editOverlayVisible && (
                    <DocumentItemForm
                        showProductDimensionsInfo={props.showProductDimensionsInfo}
                        canSubmit={props.canSubmit}
                        closeForm={() => setEditOverlayVisible(false)}
                        document={props.form.values}
                        units={props.units}
                        enterFullPriceEnabled={props.enterFullPriceEnabled}
                        canChangeVat={props.canChangeVat}
                        editPurchasePrice={props.editPurchasePrice}
                        defaultVat={props.defaultVat}
                        isAccountingVat={props.isAccountingVat}
                        onSubmit={item => {
                            props.form.setFieldValue(props.field.name, item)

                            const res: any = props.form.submitForm()

                            setEditOverlayVisible(false)

                            return res
                        }}
                        printSettings={props.printSettings}
                        initialValues={props.field.value}
                    />
                )}
            </InlineInputEdit>

            <span className="table__cell mobile-hide table__cell--prefixed" data-prefix={t('Invoice.Table.Header.name')}>
                <div className="overflow">
                    <InlineInputEdit
                        currentValue={props.field.value.name}
                        multiline
                        as="textarea"
                        preventSubmitOnEnter={true}
                        className="table__item-inline-title"
                        placeholderKey="Invoice.Table.Item.titlePlaceholder"
                        onSave={({ newValue }) => {
                            props.form.setFieldValue(`${props.field.name}.name`, newValue)
                            props.form.submitForm()
                        }}
                    />

                    <InlineInputEdit
                        currentValue={props.field.value.additionalNote}
                        multiline
                        as="textarea"
                        preventSubmitOnEnter={true}
                        placeholderKey="Invoice.Table.Item.descriptionPlaceholder"
                        className={
                            props.field.value.additionalNote
                                ? 'subscript-note ng-inline-edit__text'
                                : 'subscript-note note-placeholder ng-inline-edit__text'
                        }
                        onSave={({ newValue }) => {
                            props.form.setFieldValue(`${props.field.name}.additionalNote`, newValue)
                            props.form.submitForm()
                        }}
                    />
                </div>
                {props.field.value.imagePath ? (
                    <ImageWithAuthorization className="mod-margin-top-8" src={props.field.value.imagePath} />
                ) : null}
            </span>

            <span
                data-prefix={t('Invoice.Table.Header.amount')}
                className="table__cell table__cell__item-total table__cell__price table__cell--prefixed">
                <InlineNumberEdit
                    currentValue={props.field.value.quantity}
                    validationSchema={quantitySchema}
                    required={true}
                    onSave={({ newValue }) => {
                        onRowFieldChange('quantity', newValue)

                        props.form.setFieldValue(`${props.field.name}.quantity`, newValue)

                        props.form.submitForm()
                    }}
                />

                {props.field.value.unit.name ? <div className="subscript">{props.field.value.unit.name}</div> : null}
            </span>
            {enterFullPriceEnabled ? (
                <span
                    data-prefix={t('Invoice.Table.Header.price')}
                    className="table__cell table__cell__item-total table__cell__price table__cell--prefixed">
                    <span>
                        <CurrencyLabel
                            amount={
                                props.field.value.fullPrice
                                    ? round(
                                          round(
                                              props.field.value.fullPrice /
                                                  props.field.value.quantity /
                                                  (1 - props.field.value.discount / 100)
                                          )
                                      )
                                    : 0
                            }
                            currency={props.form.values.details.documentCurrency}></CurrencyLabel>
                    </span>

                    {exchangeRate !== 1 && (
                        <span className="subscript">
                            <CurrencyLabel
                                customFormatter={val => <>{val}</>}
                                amount={(props.field.value.fullPrice / props.field.value.quantity) * exchangeRate}
                                currency={props.form.values.details.clientCurrency}></CurrencyLabel>
                        </span>
                    )}
                </span>
            ) : (
                <span
                    data-prefix={t('Invoice.Table.Header.price')}
                    className="table__cell table__cell__item-total table__cell__price table__cell--prefixed">
                    <InlineCurrencyEdit
                        currentValue={props.field.value.salePrice}
                        inline
                        currency={props.form.values.details.documentCurrency}
                        required={true}
                        onSave={({ newValue }) => {
                            onRowFieldChange('salePrice', newValue)

                            props.form.setFieldValue(`${props.field.name}.salePrice`, newValue)

                            props.form.submitForm()
                        }}
                    />

                    {exchangeRate !== 1 && (
                        <span className="subscript">
                            <CurrencyLabel
                                customFormatter={val => <>{val}</>}
                                amount={props.field.value.salePrice * exchangeRate}
                                currency={props.form.values.details.clientCurrency}></CurrencyLabel>
                        </span>
                    )}
                </span>
            )}

            <span
                data-prefix={t('Invoice.Table.Header.discount')}
                className="table__cell table__cell__item-total table__cell__price table__cell--prefixed">
                <InlineNumberEdit
                    currentValue={props.field.value.discount}
                    required={true}
                    validationSchema={discountSchema}
                    onSave={({ newValue }) => {
                        onRowFieldChange('discount', newValue)

                        props.form.setFieldValue(`${props.field.name}.discount`, newValue)

                        props.form.submitForm()
                    }}>
                    %
                </InlineNumberEdit>

                {enterFullPriceEnabled ? (
                    <span className="subscript">
                        <CurrencyLabel
                            customFormatter={val => <>{val}</>}
                            amount={props.field.value.fullPrice / (1 - props.field.value.discount / 100) - props.field.value.fullPrice}
                            currency={props.form.values.details.documentCurrency}></CurrencyLabel>
                    </span>
                ) : (
                    <span className="subscript">
                        <CurrencyLabel
                            customFormatter={val => <>{val}</>}
                            amount={
                                props.field.value.salePrice * props.field.value.quantity -
                                props.field.value.salePrice * props.field.value.quantity * (1 - props.field.value.discount / 100)
                            }
                            currency={props.form.values.details.documentCurrency}></CurrencyLabel>
                    </span>
                )}
            </span>
            {enterFullPriceEnabled ? (
                <span
                    data-prefix={t('Invoice.Table.Header.total')}
                    className="table__cell table__cell__item-total table__cell__price table__cell--prefixed">
                    <InlineCurrencyEdit
                        currentValue={props.field.value.fullPrice}
                        currency={props.form.values.details.documentCurrency}
                        required={true}
                        onSave={({ newValue }) => {
                            onRowFieldChange('fullPrice', newValue)

                            props.form.setFieldValue(`${props.field.name}.fullPrice`, newValue)

                            props.form.submitForm()
                        }}
                    />
                    {exchangeRate != 1 && props.form.values.details.documentCurrency != props.form.values.details.clientCurrency && (
                        <span className="subscript">
                            <CurrencyLabel
                                customFormatter={val => <>{val}</>}
                                amount={props.field.value.fullPrice * exchangeRate}
                                currency={props.form.values.details.clientCurrency}></CurrencyLabel>
                        </span>
                    )}
                </span>
            ) : (
                <span
                    data-prefix={t('Invoice.Table.Header.total')}
                    className="table__cell table__cell__item-total table__cell__price table__cell--prefixed">
                    <CurrencyLabel
                        amount={props.field.value.salePrice * props.field.value.quantity * (1 - props.field.value.discount / 100)}
                        currency={props.form.values.details.documentCurrency}></CurrencyLabel>

                    {exchangeRate != 1 && props.form.values.details.documentCurrency != props.form.values.details.clientCurrency && (
                        <span className="subscript">
                            <CurrencyLabel
                                customFormatter={val => <>{val}</>}
                                amount={
                                    props.field.value.salePrice *
                                    props.field.value.quantity *
                                    (1 - props.field.value.discount / 100) *
                                    exchangeRate
                                }
                                currency={props.form.values.details.clientCurrency}></CurrencyLabel>
                        </span>
                    )}
                </span>
            )}

            <div className="table__cell table__cell__item-total table__cell__price">
                <GridMenuCell>
                    <GridMenuActionLink
                        onClick={() => setEditOverlayVisible(true)}
                        translationKey="Invoice.Table.ItemMenu.edit"></GridMenuActionLink>
                    <GridMenuActionLink onClick={props.removeRow} translationKey="Invoice.Table.ItemMenu.delete"></GridMenuActionLink>
                </GridMenuCell>
            </div>
        </li>
    )
}
